<template>
  <!--    编辑-->
  <el-dialog
    :title="formTitle"
    :visible.sync="messageBox"
    width="500px"
    class="addAlameBox"
    :close-on-click-modal="false"
  >
    <FormBox
      ref="formRef"
      :showFlag="showFlag"
      :formArr="formArr"
      :record="addForm"
      @saveForm="saveForm"
      @handleCancel="handleCancel"
      labelWidth="6vw"
      :fileListModify="fileListModify"
    ></FormBox>
  </el-dialog>
</template>

<script>
import FormBox from "@/components/lxw/Form/index.vue";
export default {
  components: { FormBox },
  props: {
    formTitle: {
      type: String,
      default: "新增",
    },
    messageBox: {
      type: Boolean,
      default: false,
    },
    showFlag: {
      type: Boolean,
      default: false,
    },
    record: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },

  data() {
    return {
      formArr: [
        {
          label: "任务名称",
          key: "farmTaskId",
          type: "Select",
          options: []
        },
        {
          label: "产品名称",
          key: "inputId",
          type: "Select",
          options: []
        },
        {
          label: "规格",
          key: "unit",
          type: "Input",
        },
        {
          label: "使用数量",
          key: "useNumber",
          type: "InputNumber",
        },
        {
          label: "出库人",
          key: "useUser",
          type: "Input",
        },
        {
          label: "出库人联系方式",
          key: "useUserTel",
          type: "Input",
        },
        {
          label: "出库时间",
          key: "useTime",
          type: "DateTime",
        },
        {
          label: "用途",
          key: "usePurpose",
          type: "Textarea",
        },

      ],
      addForm: {},
      rules: {
        // baseName: [
        //   {required: true, message: "基地名称不可为空", trigger: ["blur", "change"]},
        // ],
      },
      fileListModify: [],
    };
  },
  watch: {
    record: {
      handler(newVal, oldVal) {
        this.addForm = newVal;
        if(newVal.inputImg){
          this.$getBlob("/fileops/show", {
            fileName: newVal.inputImg,
          }).then((res) => {
            let fileNames = res.config.params.fileName;
            let fileData = fileNames.split(".")[1];
            let blob = new Blob([res.data],{type: 'image/'+ fileData})
            let url = window.URL.createObjectURL(blob);
            this.fileListModify = [{name: newVal.inputImg, url: url, blob, fileData}];
          })
        }else{
          this.fileListModify = [];
        }

      },

      immediate: true,
      deep: true,
    },
  },
  async created(){
    let arr = await this.$get("/farmTask/getFarmTaskList", {})
    let data = arr.data
    if (data.state == "success") {
      let a = []
      data.datas.forEach(item => {
        a.push({
          value: item.id,
          label: item.taskName
        })
      })
      let ind = this.formArr.findIndex(item => item.key == 'farmTaskId')
      this.formArr[ind].options = a
    }
    let arr2 = await this.$get("/inputInfo/list", {})
    let data2 = arr2.data
    if (data2.state == "success") {
      let b = []
      data2.data.forEach(item => {
        b.push({
          value: item.id,
          label: item.inputName
        })
      })
      let ind2 = this.formArr.findIndex(item => item.key == 'inputId')
      this.formArr[ind2].options = b
    }

  },
  mounted() {
    // //console.log(this.record, "record");
  },
  methods: {
    getFileListAndForm(obj) {
      if (obj && obj.fileList) {
        const { fileList } = obj;
        let list = [];
        fileList.forEach((item) => {
          if (
            item.response &&
            item.response.data &&
            item.response.data.fileName
          ) {
            list.push(item.response.data.fileName);
          }
        });
        this.filesList = list;
      }
    },

    // 确认提交
    saveForm(param) {

      let url = "/inputPurchase/saveUse";
      // if(this.formTitle == '新增'){
      //   delete this.addForm.id;
      // }

      this.$postJSON(url, param).then((res) => {
        if (res.data.state == "success") {
          if (this.formTitle == "新增") {
            this.$message.success("添加成功");
          } else {
            this.$message.success("修改成功");
          }
          this.$emit("success");
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    // 重置字段
    resetForm() {
      this.$refs["formRef"].resetForm();
    },

    handleCancel() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>

</style>